import axios from 'axios';
import { HOST_API } from 'src/config-global';
import { paths } from 'src/routes/paths';
import { refreshToken, setSession } from 'src/auth/context/jwt/utils';
import { enqueueSnackbar } from 'notistack';

const axiosInstance = axios.create({ baseURL: HOST_API });

// List of URLs that don't require authentication
const nonAuthUrls = [
  '/auth/registration/',
  '/auth/login/',
  '/auth/password/reset/',
  '/auth/password/reset/confirm/',
  '/auth/registration/verify-email/',
  '/auth/registration/resend-email/',
  '/token/refresh/',
  '/auth/google/',
  '/auth/facebook/',
  '/auth/microsoft/',
];

// Function to check if a URL is non-auth
const isNonAuthUrl = (url) => {
  return nonAuthUrls.some(nonAuthUrl => url.includes(nonAuthUrl));
};

// Request interceptor to add auth token to headers
axiosInstance.interceptors.request.use(
  (config) => {
    if (!isNonAuthUrl(config.url)) {
      const token = localStorage.getItem('accessTokenResponse');
      if (token) {
        config.headers['Authorization'] = `Bearer ${token}`;
      }
    }
    return config;
  },
  (error) => Promise.reject(error)
);

// Response interceptor to handle token refresh
axiosInstance.interceptors.response.use(
  (response) => response,
  async (error) => {
    let originalRequest = error.config;
    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      try {
        const refreshTokenResponse = localStorage.getItem('refreshToken') || localStorage.getItem('refreshTokenResponse');
        const response = await axios.post(`${HOST_API}/api/token/refresh/`, { refresh: refreshTokenResponse });
        const { access } = response.data;
        if (access) {
          localStorage.setItem('accessToken', access);
          localStorage.setItem('accessTokenResponse', access);
          setSession(access);
          axios.defaults.headers.common['Authorization'] = `Bearer ${access}`;
          originalRequest.headers['Authorization'] = `Bearer ${access}`;
          axiosInstance(originalRequest);
          window.location.reload();
        } else {
          localStorage.clear();
          window.location.href = paths.auth.jwt.login;
        }
        return access;
      } catch (error) {
        localStorage.clear();
        console.error('Token refresh error:', error);
        return null;
      }
    }

    if (error.response.status === 403 && !originalRequest._retry) {
      originalRequest._retry = true;
      try {
        enqueueSnackbar('You do not have permission to perform this action.', { variant: 'error' });
      } catch (error) {
        console.error('error: ', error);
        enqueueSnackbar(error, { variant: 'error' });
      }
    }

    // if (error.response.status === 404 && !originalRequest._retry) {
    //   originalRequest._retry = true;
    //   try {
    //     window.location.replace('/404');
    //   } catch (error) {
    //     console.error('error: ', error);
    //     enqueueSnackbar(error, { variant: 'error' });
    //   }
    // }
  }
);

export default axiosInstance;

// Fetcher function
export const fetcher = async (args) => {
  const [url, config] = Array.isArray(args) ? args : [args];
  const res = await axiosInstance.get(url, { ...config });
  return res.data;
};

// API Endpoints Configuration
export const endpoints = {
  chat: '/api/chat',
  kanban: '/api/kanban',
  calendar: '/api/calendar',
  auth: {
    me: '/api/auth/me',
    login: '/api/auth/login/',
    register: '/api/auth/registration/',
    passwordReset: '/api/auth/password/reset/',
    passwordResetConfirm: '/api/auth/password/reset/confirm/',
    passwordChange: '/api/auth/password/change/',
    verifyEmail: '/api/auth/registration/verify-email/',
    resendEmailVerification: '/api/auth/registration/resend-email/',
    tokenRefresh: '/api/token/refresh/',
    social: {
      google: '/api/auth/google/register/',
      facebook: '/api/auth/facebook/',
      microsoft: '/api/auth/microsoft/',
    },
  },
  mail: {
    list: '/api/mail/list',
    details: '/api/mail/details',
    labels: '/api/mail/labels',
  },
  post: {
    list: '/api/post/list',
    details: '/api/post/details',
    latest: '/api/post/latest',
    search: '/api/post/search',
  },
  product: {
    list: '/api/product/list',
    details: '/api/product/details',
    search: '/api/product/search',
  },
};
