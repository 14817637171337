import PropTypes from 'prop-types';
import { FormProvider as Form } from 'react-hook-form';

import './form-provider.css'
import { style } from '@mui/system';

// ----------------------------------------------------------------------

export default function FormProvider({ children, onSubmit, methods,classname }) {
  return (
    <div className={classname ? classname : 'register-form'} style={{width:"100%"}}>
      <Form {...methods}>
        <form onSubmit={onSubmit}>
          {children}
        </form>
      </Form>
    </div>
  );
}

FormProvider.propTypes = {
  children: PropTypes.node,
  methods: PropTypes.object,
  onSubmit: PropTypes.func,
};
