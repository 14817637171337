import { createAction } from '@reduxjs/toolkit';
import { useServerAPI } from 'src/api/ServerAPI';

export const FETCH_MEMBERS_REQUEST = 'FETCH_MEMBERS_REQUEST';
export const FETCH_MEMBERS_SUCCESS = 'FETCH_MEMBERS_SUCCESS';
export const FETCH_MEMBERS_FAILURE = 'FETCH_MEMBERS_FAILURE';
export const ADD_MEMBER = 'ADD_MEMBER';
export const UPDATE_MEMBER = 'UPDATE_MEMBER';
export const DELETE_MEMBER = 'DELETE_MEMBER';
export const SHOW_ADD_AGENT_MODAL = 'SHOW_ADD_AGENT_MODAL';
export const HIDE_ADD_AGENT_MODAL = 'HIDE_ADD_AGENT_MODAL';
export const SHOW_EDIT_AGENT_MODAL = 'SHOW_EDIT_AGENT_MODAL';
export const HIDE_EDIT_AGENT_MODAL = 'HIDE_EDIT_AGENT_MODAL';
export const SHOW_DELETE_AGENT_MODAL = 'SHOW_DELETE_AGENT_MODAL';
export const HIDE_DELETE_AGENT_MODAL = 'HIDE_DELETE_AGENT_MODAL';

// export const fetchMembersRequest = createAction(FETCH_MEMBERS_REQUEST);
// export const fetchMembersSuccess = createAction(FETCH_MEMBERS_SUCCESS);
// export const fetchMembersFailure = createAction(FETCH_MEMBERS_FAILURE);
// const serverAPI = useServerAPI();

// export const fetchMembers = (organizationId) => async (dispatch) => {
//     dispatch(fetchMembersRequest());
//     try {
//         const response = await serverAPI.getOrganization(organizationId);
//         dispatch(fetchMembersSuccess(response.data.members));
//     } catch (error) {
//         dispatch(fetchMembersFailure(error.message));
//     }
// };

// Other action creators remain the same

export const fetchMembersSuccess = (members) => ({
    type: FETCH_MEMBERS_SUCCESS,
    payload: members,
});
export const addMember = (member) => ({
    type: ADD_MEMBER,
    payload: member
});

export const updateMember = (member) => ({
    type: UPDATE_MEMBER,
    payload: member
});

export const deleteMember = (memberId) => ({
    type: DELETE_MEMBER,
    payload: memberId
});

export const showAddAgentModal = () => ({
    type: SHOW_ADD_AGENT_MODAL
});

export const hideAddAgentModal = () => ({
    type: HIDE_ADD_AGENT_MODAL
});

export const showEditAgentModal = (member) => ({
    type: SHOW_EDIT_AGENT_MODAL,
    payload: member
});

export const hideEditAgentModal = () => ({
    type: HIDE_EDIT_AGENT_MODAL
});

export const showDeleteAgentModal = (member) => ({
    type: SHOW_DELETE_AGENT_MODAL,
    payload: member
});

export const hideDeleteAgentModal = () => ({
    type: HIDE_DELETE_AGENT_MODAL
});
