import PropTypes from 'prop-types';
import { useEffect, useReducer, useCallback, useMemo } from 'react';
import axiosInstance, { endpoints } from 'src/utils/axios';
import { AuthContext } from './auth-context';
import { isValidToken, setSession, refreshToken } from './utils';
import { useRouter } from 'src/routes/hooks';
import { setOrganizationId, setOrganizationName, setProfileData } from '../../../store/profileActions';
import { setUserRole as setUserRoleAction, setUserRoles, setAccountEntity, setAccountEntityId, setSuperUser, setOrgCreated, setPlan, setAddCreatorAvailable, setMonitoringAvailable } from '../../../store/roleActions';
import { paths } from 'src/routes/paths';
import { useDispatch } from 'react-redux';
import axios from 'axios';

// ----------------------------------------------------------------------

const initialState = {
  user: null,
  loading: true,
};

export const reducer = (state, action) => {
  switch (action.type) {
    case 'INITIAL':
      return {
        loading: false,
        user: action.payload.user,
      };
    case 'LOGIN':
      return {
        ...state,
        user: action.payload.user,
      };
    case 'REGISTER':
      return {
        ...state,
        user: action.payload.user,
      };
    case 'LOGOUT':
      return {
        ...state,
        user: null,
      };
    default:
      return state;
  }
};

// ----------------------------------------------------------------------

const STORAGE_KEY = 'accessToken';

export function AuthProvider({ children }) {
  const router = useRouter();
  const reduxDispatch = useDispatch();
  const [state, dispatch] = useReducer(reducer, initialState);

  const setProfileDataAction = useCallback((name, email, profile_img, signupSource, agencyName) => {
    reduxDispatch(setProfileData(name, email, profile_img, signupSource, agencyName));
  }, [reduxDispatch]);

  const setOrganizationNameAction = useCallback((orgName) => {
    reduxDispatch(setOrganizationName(orgName));
  }, [reduxDispatch]);

  const setOrganizationIdAction = useCallback((orgName) => {
    reduxDispatch(setOrganizationId(orgName));
  }, [reduxDispatch]);

  const dispatchUserRole = useCallback((role) => {
    reduxDispatch(setUserRoleAction(role));
  }, [reduxDispatch]);

  const dispatchUserRoles = useCallback((roles) => {
    reduxDispatch(setUserRoles(roles));
  }, [reduxDispatch]);

  const dispatchSuperUser = useCallback((is_superuser) => {
    reduxDispatch(setSuperUser(is_superuser));
  }, [reduxDispatch]);

  const dispatchCreatedOrg = useCallback((is_org_created) => {
    reduxDispatch(setOrgCreated(is_org_created));
  }, [reduxDispatch]);

  const dispatchAccountEntity = useCallback((entity) => {
    reduxDispatch(setAccountEntity(entity));
  }, [reduxDispatch]);

  const dispatchAccountEntityId = useCallback((entity) => {
    reduxDispatch(setAccountEntityId(entity));
  }, [reduxDispatch]);

  const dispatchIsMonitoringAvailable = useCallback((isMonitoringAvailable) => {
    reduxDispatch(setMonitoringAvailable(isMonitoringAvailable));
  }, [reduxDispatch]);

  const dispatchIsAddCreatorAvailable = useCallback((isAddCreatorAvailable) => {
    reduxDispatch(setAddCreatorAvailable(isAddCreatorAvailable));
  }, [reduxDispatch]);

  const dispatchPlan = useCallback((plan) => {
    reduxDispatch(setPlan(plan));
  }, [reduxDispatch]);

  const initialize = useCallback(async () => {
    try {
      let accessToken = localStorage.getItem(STORAGE_KEY);

      if (accessToken) {
        setSession(accessToken);
        const response = await axiosInstance.get(endpoints.auth.me);
        const { user } = response.data;
        localStorage.setItem('isFirstLogin', user?.is_first_login);
        dispatchUserRole(user.active_role.role);
        dispatchUserRoles(user.roles);
        localStorage.setItem('email', user.email);
        localStorage.setItem('username', user.full_name);
        localStorage.setItem('role', user.active_role.role);
        localStorage.setItem('isFirstLogin', user.is_first_login);
        dispatchSuperUser(user.is_superuser);
        dispatchCreatedOrg(user.able_to_create_org);
        dispatchAccountEntity(user.active_role.entity.entity_type);
        dispatchAccountEntityId(user.active_role.entity.id);
        localStorage.setItem('isUserVerified', user.is_verified);
        if (!localStorage.getItem('googleSignedUp')) {
          localStorage.setItem('googleSignedUp', false);
        }
        if (user?.influencerId !== null) {
          localStorage.setItem('influencerId', user?.influencerId);
        }

        if (response.data.success) {
          const settings = await axiosInstance.get(`/api/organizations/${user.active_role.entity.id}/settings/`);
          dispatchIsMonitoringAvailable(settings.data.can_monitor_creator)
          dispatchIsAddCreatorAvailable(settings.data.can_add_creator)
          dispatchPlan(settings.data.plan)
          setProfileDataAction(
            user.full_name,
            user.email,
            user.photo_url,
            user.signup_source,
          );
          setOrganizationNameAction(user.active_role.entity.name);
          setOrganizationIdAction(user.active_role.entity.id);
        } else {
          localStorage.clear();
        }

        dispatch({
          type: 'INITIAL',
          payload: {
            user,
          },
        });
      } else {
        dispatch({
          type: 'INITIAL',
          payload: {
            user: null,
          },
        });
      }
    } catch (error) {
      console.error(error);
      localStorage.clear();
      dispatch({
        type: 'INITIAL',
        payload: {
          user: null,
        },
      });
    }
  }, [dispatch]);

  useEffect(() => {
    initialize();
  }, [initialize]);

  // LOGIN
  const login = useCallback(async (email, password) => {
    try {
      const data = { email, password };
      const response = await axiosInstance.post(endpoints.auth.login, data);
      const { access, refresh, user } = response.data;

      dispatchUserRoles(user.roles);

      dispatchUserRole(user.active_role.role);
      dispatchAccountEntity(user.active_role.entity.entity_type);
      dispatchAccountEntityId(user.active_role.entity.id);

      localStorage.setItem('accessTokenResponse', access);
      localStorage.setItem('accessToken', access);
      localStorage.setItem('refreshTokenResponse', refresh);
      localStorage.setItem('refreshToken', refresh);

      if (!user.is_verified) {
        localStorage.setItem('sessionUserType', user.user_type);
        window.location.href = paths.auth.jwt.register;
      } else {
        router.push(paths.dashboard.search);

        setSession(access);
        setProfileDataAction(user.full_name, user.email, user.photo_url);

        dispatch({
          type: 'LOGIN',
          payload: { user },
        });
      }
    } catch (e) {
      console.log('e');
    }
  }, [router, dispatch, setProfileDataAction]);

  // REGISTER
  const register = useCallback(async (email, name, password) => {
    const data = { email, name, password };
    const response = await axiosInstance.post(endpoints.auth.register, data);
    const { access, refresh, user } = response.data;

    localStorage.setItem(STORAGE_KEY, access);
    localStorage.setItem('refreshTokenResponse', refresh);
    setSession(access);

    dispatch({
      type: 'REGISTER',
      payload: { user },
    });
  }, [dispatch]);

  // LOGOUT
  const logout = useCallback(async () => {
    setSession(null);
    localStorage.clear();
    dispatchSuperUser(false);
    dispatch({ type: 'LOGOUT' });
  }, [dispatch]);

  const checkAuthenticated = state.user ? 'authenticated' : 'unauthenticated';
  const status = state.loading ? 'loading' : checkAuthenticated;

  const memoizedValue = useMemo(
    () => ({
      user: state.user,
      method: 'jwt',
      loading: status === 'loading',
      authenticated: status === 'authenticated',
      unauthenticated: status === 'unauthenticated',
      login,
      register,
      logout,
    }),
    [login, logout, register, state.user, status]
  );

  return <AuthContext.Provider value={memoizedValue}>{children}</AuthContext.Provider>;
}

AuthProvider.propTypes = {
  children: PropTypes.node,
};
