// components
import Iconify from 'src/components/iconify';

// ----------------------------------------------------------------------

export const navConfig = [
  {
    title: 'Influencers & Creators',
    icon: <Iconify icon="solar:file-bold-duotone" />,
    path: '',
  },
  {
    title: 'Brands & Agencies',
    icon: <Iconify icon="solar:file-bold-duotone" />,
    path: '',
  },
  {
    title: 'About Us',
    icon: <Iconify icon="solar:file-bold-duotone" />,
    path: '',
  },
  {
    title: 'Contact',
    icon: <Iconify icon="solar:notebook-bold-duotone" />,
    path: '',
  }
];
