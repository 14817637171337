import PropTypes from 'prop-types';
import Stack from '@mui/material/Stack';

// ----------------------------------------------------------------------

export default function AuthClassicLayout({ children }) {

  const gradientStyle = {
    background: 'linear-gradient(180deg, rgb(253, 253, 253) 66%, rgba(184, 182, 244) 100%)'
  };

  const renderContent = (
    <Stack
      sx={{
        width: 1,
        mx: 'auto',
        maxWidth: 480,
        px: { xs: 2, md: 6 },
        py: { xs: 15, md: 12},
      }}
    >
      {children}
    </Stack>
  );

  return (
    <Stack
      component="main"
      direction="row"
      sx={{
        minHeight: 1,
        ...gradientStyle,
      }}
    >
      {renderContent}
    </Stack>
  );
}

AuthClassicLayout.propTypes = {
  children: PropTypes.node,
};
