import { SET_PROFILE_DATA, CLEAR_PROFILE_DATA, SET_ORGANIZATION_NAME, SET_ORGANIZATION_ID } from './profileActions';

const initialState = {
  name: '',
  email: '',
  profile_img: '',
  signupSource: '',
};

const profileReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_PROFILE_DATA:
      return {
        ...state,
        ...action.payload,
      };
    case SET_ORGANIZATION_NAME:
      return {
        ...state,
        orgName: action.payload,
      };
    case SET_ORGANIZATION_ID:
      return {
        ...state,
        orgId: action.payload,
      };
    case CLEAR_PROFILE_DATA:
      return initialState;
    default:
      return state;
  }
};

export default profileReducer;