// routes
import { HOST_API } from 'src/config-global';
import { paths } from 'src/routes/paths';
// utils
import axios, { endpoints } from 'src/utils/axios';
import { useSelector, useDispatch } from 'react-redux';
import { setProfileData as profileData, clearProfileData } from '../../../store/profileActions';
import axiosInstance from 'src/utils/axios';

// ----------------------------------------------------------------------

function jwtDecode(token) {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split('')
      .map((c) => `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`)
      .join(''),
  );

  return JSON.parse(jsonPayload);
}

// ----------------------------------------------------------------------

export const isValidToken = (accessToken) => {
  if (!accessToken) {
    return false;
  }

  const decoded = jwtDecode(accessToken);

  const currentTime = Date.now() / 1000;

  return decoded.exp > currentTime;
};

// ----------------------------------------------------------------------

export const tokenExpired = async () => {
  try {
    const newAccessToken = await refreshToken();
    if (newAccessToken) {
      setSession(newAccessToken);
    } else {
      throw new Error('Token refresh failed');
    }
  } catch (error) {
    // alert('Session expired. Please log in again.');  TODO: enable this popup (need some global context variable to manage timer)
    localStorage.removeItem('accessToken');
    window.location.href = paths.auth.jwt.login;
  }
};

// ----------------------------------------------------------------------

export const setSession = (accessToken) => {
  if (accessToken) {
    localStorage.setItem('accessToken', accessToken);
    axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;

    // Decode the token to get its expiration time
    const { exp } = jwtDecode(accessToken);
    const currentTime = Date.now() / 1000;
    const timeLeft = exp - currentTime;

    // Set a timeout to call tokenExpired when the token is about to expire
    setTimeout(() => {
      tokenExpired();
    }, timeLeft * 1000 - 10000); // Refresh the token 10 sec before it expires
  } else {
    localStorage.removeItem('accessToken');
    delete axios.defaults.headers.common.Authorization;
  }
};

export const registrationStepcall = (step) => {
  const username = useSelector((state) => state.profile.name);
  const agencyName = useSelector((state) => state.profile.orgName);
  const headers = {
    headers: {
      'Authorization': 'Token 3131458cbf269ec9efbdf3d7c3cf0f8666edef2b',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      access_token: localStorage.getItem('accessTokenResponse'),
      name: username,
      agencyName: agencyName,
    }),
  };
  const apiUrl = `${HOST_API}/api/auth/register`;
  fetch(apiUrl, {
    method: 'PATCH',
    headers: headers.headers,
    body: headers.body,
  }).then((response) => response.json());
};

export const refreshToken = async () => {
  try {
    const refreshTokenResponse = localStorage.getItem('refreshToken') || localStorage.getItem('refreshTokenResponse');
    const response = await axiosInstance.post(endpoints.auth.tokenRefresh, { refresh: refreshTokenResponse });
    const { access } = response.data;
    localStorage.setItem('accessToken', access);
    localStorage.setItem('accessTokenResponse', access);
    setSession(access);
    return access;
  } catch (error) {
    localStorage.clear();
    console.error('Token refresh error:', error);
    return null;
  }
};
