import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { memo, useCallback, useEffect, useState } from 'react';
import './index.css';
import { LoadingButton } from '@mui/lab';
import { refreshToken } from 'src/auth/context/jwt/utils';
import { useServerAPI } from 'src/api/ServerAPI';
import { Box, CircularProgress, useMediaQuery } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { setOrganizationName } from 'src/store/profileActions';
import { setAccountEntityId } from 'src/store/roleActions';

function AdminPanel() {
    const serverAPI = useServerAPI();
    const [list, setList] = useState([]);
    const [value, setValue] = useState([]);
    const [loader, setLoader] = useState(false);
    const [active, setActive] = useState(false);

    const reduxDispatch = useDispatch();

    const isMobile = useMediaQuery('(max-width:900px)');

    const dispatchAccountEntityId = useCallback((entity) => {
        reduxDispatch(setAccountEntityId(entity));
    }, [reduxDispatch]);

    const setOrganizationNameAction = useCallback((orgName) => {
        reduxDispatch(setOrganizationName(orgName));
    }, [reduxDispatch]);

    const role = useSelector((state) => state.role.superUser);

    useEffect(() => {
        if (role) {
            const load = async () => {

                const result = await serverAPI.getOrganizations();
                const transformResults = (results) => {
                    return results?.map(result => ({
                        value: result.id,
                        label: result.name
                    }));
                };
                const transformedArray = transformResults(result?.results);
                setList(transformedArray);
                const activeOrg = await serverAPI.getActiveOrganization();
                if (activeOrg.name) {
                    setOrganizationNameAction(activeOrg.name);
                    dispatchAccountEntityId(activeOrg.id);
                    setActive(activeOrg);
                    setValue(activeOrg.id);
                }
            }
            load();
        }
    }, [role])


    const handleChange = (e) => {
        setValue(e.target.value);
    }

    const refreshUser = async () => {
        setLoader(true);
        await refreshToken();
        window.location.reload();
        setLoader(false);
    }

    const selectOrgamization = async (list) => {
        const result = await serverAPI.activateOgranization(list.value);
        localStorage.setItem('accessTokenResponse', result?.access_token);
        localStorage.setItem('accessToken', result?.access_token);
        window.location.reload();
    }

    return (
        <FormControl style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }} fullWidth>
            {!isMobile && <p className='title' style={{ margin: '0 20px 0 0', }}>Organization: </p>}
            <div style={{ width: isMobile ? '200px' : '300px', }}>
                <Select
                    sx={{ height: '40px', width: isMobile ? '200px' : '300px', padding: '5px 0px' }}
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    onChange={handleChange}
                    value={value}
                >
                    {list.map(list => {
                        return <MenuItem onClick={() => selectOrgamization(list)}
                            key={list.value}
                            value={list.value}
                            style={{ overflow: 'hidden', width: '100%', wordBreak: 'keep-all', padding: '15px 10px' }} >
                            {list.label}
                        </MenuItem>
                    })}
                </Select>
            </div>
            <LoadingButton
                style={{ backgroundColor: '#458DE1', marginLeft: '20px', height: '40px' }}
                color="inherit"
                size="large"
                type="submit"
                variant="contained"
                onClick={() => refreshUser()}
                sx={{
                    width: '100%',
                }}
            >
                {loader ? <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                    <CircularProgress style={{ color: 'white', width: '20px', height: '20px' }} />
                </Box> : 'Back'
                }
            </LoadingButton>
        </FormControl>
    );
}

export default memo(AdminPanel);
