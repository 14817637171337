import PropTypes from 'prop-types';
// @mui
import Box from '@mui/material/Box';
import { CircularProgress } from '@mui/material';

// ----------------------------------------------------------------------

export default function LoadingScreen({ sx, ...other }) {
  return (
      <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '300px' }} {...other}>
        <CircularProgress />
      </Box>
  );
}

LoadingScreen.propTypes = {
  sx: PropTypes.object,
};
