import { paths } from 'src/routes/paths';
import { GrowthBook } from "@growthbook/growthbook-react";

const hostApi = process.env.REACT_APP_HOST_API;
const assetsApi = process.env.REACT_APP_ASSETS_API;
const clientKey = process.env.REACT_APP_CLIENT_KEY;

export const HOST_API = hostApi;
export const ASSETS_API = assetsApi;

export const growthbook = new GrowthBook({
  apiHost: "https://cdn.growthbook.io",
  clientKey: clientKey,
  enableDevMode: true,
  subscribeToChanges: true,
  trackingCallback: (experiment, result) => {
    // TODO: Use your real analytics tracking system
    console.log("Viewed Experiment", {
      experimentId: experiment.key,
      variationId: result.key
    });
  }
});

export const FIREBASE_API = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APPID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

export const AMPLIFY_API = {
  userPoolId: process.env.REACT_APP_AWS_AMPLIFY_USER_POOL_ID,
  userPoolWebClientId: process.env.REACT_APP_AWS_AMPLIFY_USER_POOL_WEB_CLIENT_ID,
  region: process.env.REACT_APP_AWS_AMPLIFY_REGION,
};

export const AUTH0_API = {
  clientId: process.env.REACT_APP_AUTH0_CLIENT_ID,
  domain: process.env.REACT_APP_AUTH0_DOMAIN,
  callbackUrl: process.env.REACT_APP_AUTH0_CALLBACK_URL,
};

export const MAPBOX_API = process.env.REACT_APP_MAPBOX_API;

export const PATH_AFTER_LOGIN = paths.dashboard.search;

export const getLogoLink = (entity) => {
  const { hostname, pathname } = window.location;
  const isInternalPage = !(['/', '/auth/jwt/login', '/auth/jwt/forgot-password', '/auth/jwt/register'].includes(pathname));
  const defaultLandingLink = hostname === 'app.vwd.ai' ? 'https://www.vwd.ai/' : 'https://qa-vwd-ai.webflow.io/';
  const influencerId = localStorage.getItem('influencerId') ?? null;
  const defaultLinks = {
    brand: `${paths.dashboard.search}`,
    organization: `${paths.dashboard.search}`,
    influencer: influencerId ? `${paths.dashboard.blank(influencerId)}` : defaultLandingLink,
  };

  return isInternalPage
    ? (defaultLinks[entity] ? defaultLinks[entity] : defaultLandingLink)
    : defaultLandingLink;
}
