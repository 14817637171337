export const SET_PROFILE_DATA = 'SET_PROFILE_DATA';
export const CLEAR_PROFILE_DATA = 'CLEAR_PROFILE_DATA';
export const SET_ORGANIZATION_NAME = 'SET_ORGANIZATION_NAME';
export const SET_ORGANIZATION_ID = 'SET_ORGANIZATION_ID';

export const setProfileData = (name, email, profile_img, signupSource) => ({
  type: SET_PROFILE_DATA,
  payload: { name, email, profile_img, signupSource },
});

export const setOrganizationName = (orgName) => ({
  type: SET_ORGANIZATION_NAME,
  payload: orgName,
});

export const setOrganizationId = (id) => ({
  type: SET_ORGANIZATION_ID,
  payload: id,
});

export const clearProfileData = () => ({
  type: CLEAR_PROFILE_DATA,
});