import { SET_USER_ROLE, SET_ACCOUNT_ENTITY, SET_ACCOUNT_ENTITY_ID, SET_SUPER_USER, SET_USER_ROLES, SET_ORG_CREATED, SET_IS_ADD_CREATOR, SET_IS_MONITORING_AVAILABLE, SET_PLAN } from './roleActions';

const initialState = {
  role: null,
  entity: null,
  roles: [],
  isOrgCreated: false,
  plan: {},

};

const roleReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_USER_ROLE:
      return {
        ...state,
        role: action.payload,
      };
    case SET_USER_ROLES:
      return {
        ...state,
        roles: action.payload,
      };
    case SET_PLAN:
      return {
        ...state,
        plan: action.payload,
      };
    case SET_IS_ADD_CREATOR:
      return {
        ...state,
        isAddCreator: action.payload,
      };
    case SET_IS_MONITORING_AVAILABLE:
      return {
        ...state,
        isMonitoringAvailable: action.payload,
      };
    case SET_SUPER_USER:
      return {
        ...state,
        superUser: action.payload,
      };
    case SET_ORG_CREATED:
      return {
        ...state,
        isOrgCreated: action.payload,
      };
    case SET_ACCOUNT_ENTITY:
      return {
        ...state,
        entity: action.payload,
      };
    case SET_ACCOUNT_ENTITY_ID:
      return {
        ...state,
        id: action.payload,
      };
    default:
      return state;
  }
};

export default roleReducer;
