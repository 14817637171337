// src/reducers.js
import {
    FETCH_MEMBERS_REQUEST,
    FETCH_MEMBERS_SUCCESS,
    FETCH_MEMBERS_FAILURE,
    ADD_MEMBER,
    UPDATE_MEMBER,
    DELETE_MEMBER,
    SHOW_ADD_AGENT_MODAL,
    HIDE_ADD_AGENT_MODAL,
    SHOW_EDIT_AGENT_MODAL,
    HIDE_EDIT_AGENT_MODAL,
    SHOW_DELETE_AGENT_MODAL,
    HIDE_DELETE_AGENT_MODAL
} from './actions';

const initialState = {
    members: [
        { id: 1, name: 'Justine Dean', email: 'justine@agency.com', status: 'Invited', avatar: 'JD' },
        { id: 2, name: 'John Doe', email: 'john@agency.com', status: 'Active', avatar: 'JD' },
        { id: 3, name: 'Jasmine Dewon', email: 'jasmine@agency.com', status: 'Active', avatar: 'JD' },
    ],
    loading: false,
    error: null,
    showAddAgentModal: false,
    showEditAgentModal: false,
    showDeleteAgentModal: false,
    selectedMember: null,
};

const membersReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_MEMBERS_REQUEST:
            return { ...state, loading: true, error: null };
        case FETCH_MEMBERS_SUCCESS:
            return {
                ...state,
                members: action.payload,
                loading: false,
                error: null,
            };
        case FETCH_MEMBERS_FAILURE:
            return { ...state, loading: false, error: action.payload };
        case ADD_MEMBER:
            return { ...state, members: [...state.members, action.payload] };
        case UPDATE_MEMBER:
            return {
                ...state,
                members: state.members.map(member =>
                    member.id === action.payload.id
                        ? {
                            ...member,
                            user: {
                                ...member.user,
                                username: action.payload.username,
                                email: action.payload.email
                            }
                        }
                        : member
                ),
            };
        case DELETE_MEMBER:
            return {
                ...state,
                members: state.members.filter(member => member.id !== action.payload),
            };
        case SHOW_ADD_AGENT_MODAL:
            return { ...state, showAddAgentModal: true };
        case HIDE_ADD_AGENT_MODAL:
            return { ...state, showAddAgentModal: false };
        case SHOW_EDIT_AGENT_MODAL:
            return { ...state, showEditAgentModal: true, selectedMember: action.payload };
        case HIDE_EDIT_AGENT_MODAL:
            return { ...state, showEditAgentModal: false, selectedMember: null };
        case SHOW_DELETE_AGENT_MODAL:
            return { ...state, showDeleteAgentModal: true, selectedMember: action.payload };
        case HIDE_DELETE_AGENT_MODAL:
            return { ...state, showDeleteAgentModal: false, selectedMember: null };
        default:
            return state;
    }
};

export default membersReducer;
