import 'src/locales/i18n';
import 'simplebar-react/dist/simplebar.min.css';
import 'yet-another-react-lightbox/styles.css';
import 'yet-another-react-lightbox/plugins/captions.css';
import 'yet-another-react-lightbox/plugins/thumbnails.css';
import 'mapbox-gl/dist/mapbox-gl.css';
import 'react-quill/dist/quill.snow.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import 'react-lazy-load-image-component/src/effects/blur.css';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import Router from 'src/routes/sections';
import ThemeProvider from 'src/theme';
import { useScrollToTop } from 'src/hooks/use-scroll-to-top';
import { useEffect, useState } from "react";
import ProgressBar from 'src/components/progress-bar';
import MotionLazy from 'src/components/animate/motion-lazy';
import SnackbarProvider from 'src/components/snackbar/snackbar-provider';
import { SettingsProvider, SettingsDrawer } from 'src/components/settings';
import { Provider } from 'react-redux';
import { CheckoutProvider } from 'src/sections/checkout/context';
import { AuthProvider, AuthConsumer } from 'src/auth/context/jwt';
import { GrowthBookProvider } from "@growthbook/growthbook-react";
import { growthbook } from './config-global';
import { ServerAPIProvider } from './api/ServerAPI';
import store from './store/store';
// import { ProductFruits } from 'react-product-fruits';
import { useFeatureIsOn } from "@growthbook/growthbook-react";

export default function App() {

  useScrollToTop();

  useEffect(() => {
    growthbook.loadFeatures();
  }, []);


  return (
    <ServerAPIProvider>
      <Provider store={store}>
        <GrowthBookProvider growthbook={growthbook}>
          <AuthProvider>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <SettingsProvider
                defaultSettings={{
                  themeMode: 'light', // 'light' | 'dark'
                  themeDirection: 'ltr', //  'rtl' | 'ltr'
                  themeContrast: 'default', // 'default' | 'bold'
                  themeLayout: 'vertical', // 'vertical' | 'horizontal' | 'mini'
                  themeColorPresets: 'default', // 'default' | 'cyan' | 'purple' | 'blue' | 'orange' | 'red'
                  themeStretch: false,
                }}
              >
                <ThemeProvider>
                  <MotionLazy>
                    <SnackbarProvider>
                      <CheckoutProvider>
                        <SettingsDrawer />
                        <ProgressBar />
                        <AuthConsumer>
                          <Router />
                        </AuthConsumer>
                      </CheckoutProvider>
                    </SnackbarProvider>
                  </MotionLazy>
                </ThemeProvider>
              </SettingsProvider>
            </LocalizationProvider>
          </AuthProvider>
        </GrowthBookProvider>
      </Provider>
    </ServerAPIProvider>
  );
}
